<template>
  <div>
 <b-card>
   <!--  <b-row>-->

   <!--    &lt;!&ndash; User Avatar &ndash;&gt;-->

   <!--    <b-col cols="12"  xl="1">-->
   <!--      <b-row>-->
   <!--        <b-col cols="12" class="d-flex justify-content-start" >-->
   <!--          <b-button-->
   <!--              @click="$router.back()"-->
   <!--              class="shadow-sm font-weight-bold  align-self-baseline float-left"-->
   <!--              variant="white">-->
   <!--            <feather-icon icon="ChevronLeftIcon" size="20"/>-->
   <!--          </b-button>-->


   <!--          <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-13.jpg')"-->
   <!--                    class="mr-n1 mt-1"-->
   <!--                    size="110px"-->
   <!--          />-->
   <!--        </b-col>-->
   <!--      </b-row>-->
   <!--    </b-col>-->
   <!--  </b-row>-->
   <b-row>
   <b-col cols="12" style="padding: 0px !important;">
     <b-overlay
         :show="tableLoading"
         rounded="sm"
     >
     <b-table
         ref="table"
         :current-page="currentPage"
         :fields="fields"
         :filter="filter"
         :filter-included-fields="filterOn"
         :items="getInvitations"
         :per-page="pagination.perPage"
         :sort-by.sync="sortBy"
         :sort-desc.sync="sortDesc"
         :sort-direction="sortDirection"
         class="mobile_table_css"
         hover
         responsive
         @row-clicked="showInvitations"
     >
       <!-- Name $ Avatar -->

       <!--          <template #cell(name)="data">-->
       <!--            <ul class="list-inline mb-0">-->
       <!--              <li class="list-inline-item">-->
       <!--                <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')" class="pull-up "/>-->
       <!--              </li>-->
       <!--              <li class="list-inline-item"> {{ data.item.name }}</li>-->
       <!--              <b-badge class="ml-1" style="padding:8px" variant="light-info">-->
       <!--                <span class="text-dark font-weight-700"> {{ data.item.role }} </span>-->
       <!--              </b-badge>-->
       <!--            </ul>-->
       <!--          </template>-->


       <!-- Delete User Button -->

       <template #cell(action)="data">
         <b-dropdown
             no-caret
             toggle-class="text-decoration-none"
             variant="link"
         >
           <template v-slot:button-content>
             <feather-icon
                 class="text-body align-middle mr-25"
                 icon="MoreVerticalIcon"
                 size="16"
             />
           </template>
           <b-dropdown-item>
             <b-button variant="outline-white" @click="resendInvitation(data.item.id)">
               <feather-icon class="mr-50" icon="SendIcon"/>
               <span>Resend</span>
             </b-button>
           </b-dropdown-item>
           <b-dropdown-item>
             <b-button variant="outline-white" @click="deleteInvitation(data.item.id)">
               <feather-icon class="mr-50" icon="TrashIcon"/>
               <span>Delete</span>
             </b-button>
           </b-dropdown-item>
         </b-dropdown>
       </template>

     </b-table>
     </b-overlay>
   </b-col>
   <b-col cols="12" v-if="noDataTable === 0" class="text-center">
     <span >No data for preview</span>
   </b-col>
     <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
       <span class="text-muted">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
     </b-col>

    <!-- Pagination -->

    <b-col class="d-flex align-items-center p-2 justify-content-center justify-content-sm-end" cols="12" sm="6">
      <b-pagination
          v-model="currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.totalRows"
          class="mb-0 mt-1 mt-sm-0 "
          first-number
          last-number
          next-class="next-item"
          prev-class="prev-item">

        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18"/>
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18"/>
        </template>
      </b-pagination>
    </b-col>
   </b-row>
 </b-card>
    <b-modal
        id="modal-center"
        v-model="showSendInvitation"
        centered
        hide-footer
        size="lg"
        title="Invitation"
    >
      <b-overlay
          :show="modelLoading"
          rounded="sm"
      >
      <b-row class="mx-1">
        <b-col cols="12" md="12">
          <validation-observer ref="InvitationSendForm">
            <b-form @submit.prevent>
              <b-form-group class="">
                <label>Email</label>
                <validation-provider
                    #default="{ errors }"
                    name="Practice"
                    rules="required|email"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="form.email"
                        v-model="form.email"
                        disabled=""
                        type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="">
                <label>status</label>
                <validation-provider
                    #default="{ errors }"
                    name="Practice"
                    rules="required|email"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="form.state"
                        v-model="form.state"
                        disabled=""
                        type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="">
                <label>Practice Name</label>
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="form.name"
                        v-model="form.name"
                        disabled=""
                        type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="">
                <label>Practice Address</label>
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="form.address"
                        v-model="form.address"
                        disabled=""
                        type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>


              <b-row>
                <b-col cols="6">
                  <b-form-group class="">
                    <label>Practice City</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="form.city"
                            v-model="form.city"
                            disabled=""
                            type="text"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="">
                    <label>Practice Post Code</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="name"
                            v-model="form.post_code"
                            disabled=""
                            type="text"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>


              <div class="float-right mb-lg-5">

                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1"
                    type="submit"
                    variant="primary"
                    @click="acceptInvitations()"
                >
                  Accept
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="warning"
                    @click="rejectInvitations()"
                >

                  Decline

                </b-button>
              </div>



            </b-form>

          </validation-observer>

        </b-col>


      </b-row>
      </b-overlay>

    </b-modal>
  </div>
</template>

<script>
import {BCard, BTabs, BTab, BButton, BDropdown, BDropdownItem} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import vSelect from 'vue-select'
import {

  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BAlert,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormInvalidFeedback,
  BSidebar,
  BFormTextarea,
  VBModal,
  BOverlay
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'
import invitations from "@/apis/modules/invitations";

export default {
  name: "invitations",
  components: {
    vSelect,
    BInputGroupPrepend,
    BCard,
    BButton,
    BTabs,
    BTab,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    BOverlay

  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  data() {
    return {
      tableLoading:false,
      modelLoading:false,

      showSendInvitation: false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      noDataTable:'',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [

        {
          key: 'practice',
          label: 'Practice'
        },

        {
          key: 'email',
          label: 'EMAIL'
        },
        {
          key: 'state',
          label: 'State'
        },
        {
          key: 'send_date',
          label: 'Sent Date',
          sortable: true
        },
        // {
        //   key: 'action',
        //   label: 'Action'
        // }

      ],
      items: [],
      form: {
        name: '',
        email: '',
        address: '',
        city: '',
        post_code: '',
        token:'',
        state:''
      }

    }
  },
  methods: {
    async getInvitations() {
      try {
        this.tableLoading = true
        const invitationsResponse = await invitations.getInvitations()
        this.noDataTable = invitationsResponse.data.data.length

        const DataArray = invitationsResponse.data.data.map((x) => ({
          id: x.id,
          email: x.email,
          practice: x.practice.name,
          state: x.state,
          send_date: this.momentFormat(x.created_at, 'ddd DD MM YYYY'),
          token: x.token,
        }))
        const paginationResponse = invitationsResponse.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        return DataArray


      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async showInvitations(item) {
      try {
        this.modelLoading = true
        this.showSendInvitation = true
        const showInvitationsResponse = await invitations.showInvitations(item.token)
        const data = showInvitationsResponse.data
        this.form.name = data.practice.name
        this.form.address = data.practice.address
        this.form.city = data.practice.city
        this.form.post_code = data.practice.postal_code
        this.form.email = data.email
        this.form.token = data.token
        this.form.state = data.state
        this.modelLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.modelLoading = false
      }
    },
    async acceptInvitations() {
      try {
        this.modelLoading = true
        const showInvitationsResponse = await invitations.accept(this.form.token)
        this.showSendInvitation = false
        this.showSuccessMessage('Invitation Accept Successful')
        this.$refs.table.refresh()
        this.modelLoading = false
        location.reload();

      } catch (error) {
        this.convertAndNotifyError(error)
        this.modelLoading = false
      }
    },
    async rejectInvitations() {
      try {
        this.modelLoading = true
        const showInvitationsResponse = await invitations.reject(this.form.token)
        this.showSendInvitation = false
        this.showSuccessMessage('Invitation Reject Successful')
        this.$refs.table.refresh()
        this.modelLoading = false
        location.reload();

      } catch (error) {
        this.convertAndNotifyError(error)
        this.modelLoading = false
      }
    }
  },
  mounted() {
  }
}

</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}

</style>
<style scoped>
.card-body {
  padding: 0px !important;
}
</style>
